.h1Secondary {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1b1c57;
  font-size: 48px;
  text-align: left;
  color: white;
  font-weight: 600;
  line-height: 60px;
  text-transform: capitalize;
  text-decoration: underline;
  -webkit-text-decoration-color: #187cae; /* Safari */
  text-decoration-color: #187cae;
  text-underline-offset: -2%;
}

.mobilewhoarewe {
  display: none;
}

.heroSection {
  display: flex;
  width: 100%;
}

.heroPic {
  display: block;
}

.whoarewePic {
  display: block;
}

.mobileBG {
  display: none;
}

/* Responsive for Full HD Screen(1080px) */
@media screen and (min-width: 1050px) {
    .heroPic {
        width: 100vw;
      display: block;
      min-height: 800px;
    }
    .heroSection {
        display: flex;
        width: 100%;
      }
}

/* Responsive for Full HD Screen(1080px) */
@media screen and (max-width: 1049px) {
  .heroPic {
    display: block;
    min-height: 800px;
  }
  .whoarewePic {
    max-width: 80%;
  }
  .heroSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .mobileBG {
    display: none;
  }
}

/* Responsive for HD Screen */
@media screen and (max-width: 955px) {
    .heroPic {
      display: none;
    }
    .whoarewePic {
      max-width: 80%;
    }
    .heroSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .mobileBG {
      display: none;
      width: 20%;
    }
  }

/* Responsive for mid device */
@media screen and (max-width: 689px) {
    .heroPic {
      display: none;
    }
    .whoarewePic {
      max-width: 80%;
    }
    .heroSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .mobileBG {
        display: block;
      width: 65%;
    }
  }

/* Responsive for tab */
@media screen and (max-width: 949px) {
  .heroPic {
    display: none;
  }
  .whoarewePic {
    display: none;
  }
  .mobilewhoarewe {
    display: block;
  }
  .mobileBG {
    display: block;
    width: 55%;
  }
}

/* Responsive for Mobile */
@media screen and (max-width: 480px) {
    .mobileBG {
      display: block;
      width: 100%;
    }
    .heroPic {
      display: none;
    }
    .whoarewePic {
      display: none;
    }
    .mobilewhoarewe {
      display: flex;
    }
    .heroSection {
      display: flex;
      flex-direction: column;
    }
    .h1Secondary {
      font-size: 30px;
      line-height: 37.5px;
    }
  }
