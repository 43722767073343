@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
/* The CSS file corresponding to index.js. */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login {
  /* padding: 25px; */
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.login_info {
  margin-top: 50px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  /* gap: 40px;
  min-width: 300px; */
}

.login_form {
  display: flex;
  text-align: left;
  flex-direction: column;
  text-align: left;
  align-items: center;
}

.login_form > label {
  color: #696b6e;
  font-weight: 600;
  font-size: 15px;
  padding-left: 20px;
  text-align: left;
  border-radius: 50px;
  min-width: 100px;
}

.labelText {
  color: #696b6e;
  font-weight: 600;
  font-size: 15px;
  padding-left: 33px;
  text-align: left;
  border-radius: 50px;
  min-width: 100px;
}

.login_form > input {
  margin: 10px;
  margin-bottom: 30px;
  margin-left: 15px;
  outline: none;
  border-radius: 10px;
  max-height: 40px;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  color: #373a47;
  width: 50%;
  max-width: 800px;
  font-weight: 600;
  min-width: 200px;
}

.loginInput {
  outline: none;
  border-radius: 28px;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  color: #373a47;
  max-width: 800px;
  font-weight: 600;
  min-width: 200px;
}

.buttonPrimary {
  background-color: #3e6ae1;
  margin: 10px;
  margin-top: 10px;
  max-height: 100px;
  border: none;
  padding: 8px 30px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.buttonPrimary:hover {
  background-color: #3457b1;
}

.login_divider {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.login_divider > span {
  font-weight: 600;
  color: #5c5e62;
}

.alternateLogin {
  color: #b6c4d9;
  font-weight: 500;
  width: 100%;
  font-size: 18px;
}

.alternateLogin > a,
a:hover {
  color: #b6c4d9;
  font-weight: 500;
  font-size: 18px;
  text-decoration: underline;
  background-color: transparent;
}

.navLink,
.navLink:hover {
  background-color: transparent;
}

* {
  box-sizing: border-box;
}

body {
  background-color: white;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 20px 0 0;
  height: 10px;
  width: 300px;
}

.progress-done {
  background: linear-gradient(to left, #5a52ff, #7bd9f6);
  box-shadow: 0 3px 3px -5px #5a52ff, 0 2px 5px #7bd9f6;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

@-webkit-keyframes example {
  0% {
    right: 100px;
  }
  100% {
    left: 100;
    right: 0px;
  }
}

@keyframes example {
  0% {
    right: 100px;
  }
  100% {
    left: 100;
    right: 0px;
  }
}

/* The element to apply the animation to */
.animate {
  position: relative;
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.custom-file-input {
  outline: none;
  border-radius: 10px;
  background-color: #1b1f20;
  color: #71768d;
  font-weight: 600;
  min-width: 200px;
  text-align: center;
  max-width: 600px;

  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 6px 12px;
  cursor: pointer;
}

.footer button {
  border-radius: 7px;
  width: 100px;
  height: 40px;
  background-color: #3457b1;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  margin: 5px;
  align-items: center;
}

.stepper{
background-color: #1b1f20!important;
color: blanchedalmond;

}

.MuiTypography-root .MuiStepLabel-label .MuiTypography-body2{
  color: white!important;
}

.signUpInput{
  border: 2px solid #EFF2F7 !important;
  border-radius: 5px;
  padding: 8px 10px;
  margin-top: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.toggleButton1{
  background-color: grey!important;
  margin-right: 5vw !important;
  border-radius: 5px;
  width: 100%;
  border: none;
  color: white!important;
  min-width: 100px;
  font-size: 100%!important;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}
.toggleButton1:active{
  background-color: #3457b1!important;
}

.toggleButton1:hover {
  background-color: #3457b1!important;
}
.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  margin: 0;
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
}

body {
  width: 100%;
  overflow-x: hidden;
}

.Container {
  height: 100vh !important;
}

.centered {
  display: grid;
  justify-content: center;
  align-items: center;
}

.button {
  height: 70% !important;
  width: 30% !important;
}

/* Burger Menu CSS [NavBar] */

.bm-item {
  padding: 15px 0;
  border-bottom: 1px solid rgb(70, 70, 70);
  text-decoration: none !important;
  margin-bottom: 10px;
  color: black !important;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  /* position: fixed; */
  width: 16px;
  height: 22px;
  right: 1.2rem !important;
  top: 3.5% !important;
}

.bm-burger-bars {
  background: black !important;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: black;
}

.bm-menu {
  background: white !important;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  z-index: 1;
}

.bm-morph-shape {
  fill: black;
}

.bm-item-list {
  color: black;
  padding: 15px 0;
  border-bottom: 1px solid rgba(194, 192, 192, 0.2);
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.popup {
  margin-top: 1000px;
}

.right {
  float: right;
  background-color: #23282b;
}

/* Maptool Style */

.mapTool {
  display: grid;
  background-color: #171719;
  width: 100vw;
  height: 100vh;
  grid-template-columns: 15% 85%;
  grid-template-areas: "mapDrawerLeft Map";
}

.Map {
  grid-area: Map;
  padding: 15px;
  background-color: #171719;
  height: 100vh;
}

.map {
  height: 75%;
  width: 97%;
  margin-top: 20px;
  margin-left: 2%;
}

.mapHeader {
  color: white;
  padding-top: 20px;
  font: Roboto;
  font-weight: 400;
  font-size: 36;
  letter-spacing: 0.085em;
}

.saveToSheets {
  margin-top: 20px;
  padding: 10px;
  margin-right: 30px;
  border-radius: 30px;
  border: none;
  color: white;
  font-weight: bold;
  letter-spacing: 0.085em;
  background-color: rgba(44, 118, 230, 0.514);
  width: 20%;
}

.saveToSheets > a {
  color: white;
  text-decoration: none;
}

.mapZoneButton {
  display: inline;
  place-items: left;
  margin-top: 20px;
  padding: 10px;
  margin-right: 30px;
  border-radius: 30px;
  border: none;
  width: 10%;
}

.mapDrawerLeft {
  grid-area: mapDrawerLeft;
  align-items: left;
  background: linear-gradient(182.6deg, #146b99 -15.3%, #01132c 97.83%);
}

.mapDrawerRight {
  grid-area: mapDrawerLeft;
  background-color: #1b1f20;
  align-items: center;
  display: flex;
  position: relative;
  place-items: start normal;
  justify-content: center;
  float: left;
  padding-top: 10px;
  height: 100%;
  width: 50%;
  margin-left: 90px;
  box-shadow: 5px 0px 5px rgb(8, 8, 8);
}
.mapDrawerInfo {
  grid-area: mapDrawerRight;
  background-color: #1b1f20;
  align-items: center;
  display: block;
  position: relative;
  place-items: start normal;
  justify-content: center;
  padding-top: 10px;
  height: 100%;
  width: 100%;
  box-shadow: 5px 0px 5px rgb(8, 8, 8);
}

.mapTools {
  display: block;
  height: 8%;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.mapTools:hover {
  background-color: rgba(44, 118, 230, 0.514);
}

.mapTools:focus-within {
  background-color: rgba(44, 118, 230, 0.514);
}

.mapToolIcon {
  margin-top: 0;
  padding-top: 25%;
}
.tool {
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: white;
  padding: 10px;
}

.editTool {
  color: rgb(73, 70, 70);
  display: inline-block;
  font-size: 12px;
  padding-left: 130px;
  padding-bottom: 10px;
}

.editTool:hover {
  color: rgba(129, 120, 120, 0.993);
  font-size: 17px;
}

.video-responsive {
  justify-content: center;
  align-items: center;
  background-color: #1b1f20;
  color: white;
  width: 100vw;
  height: 100vh;
}

.video-responsive > video {
  width: 100vw;
  height: 80vh;
  padding-top: 5%;
  align-items: center;
  background-color: #1b1f20;
}

.video-responsive h1 {
  padding-top: 10vh;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.5px;
  padding: 0px;
  font-size: 5vh;
  color: white;
}

.cardContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: 30vw;
  height: 60%;
  display: flex;
  flex-direction: column;
}

.card > form {
  margin: 10px;
  height: 100vh;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.timeslot {
  size: 12pc;
  display: inline;
  width: 100%;
  color: rgb(255, 255, 255);
  margin: 20px;
  justify-content: left;
  align-items: left;
  align-content: left;
  justify-items: left;
}

.pay {
  background-color: #3e6ae1;
  margin: 10px;
  margin-top: 30px;
  max-height: 100px;
  border: none;
  padding: 12px 40px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.mapInput {
  /* margin-top: 20px; */
  outline: none;
  border-radius: 10px;
  max-height: 50px;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  color: black;
  width: 30%;
  height: 20vh;
  max-width: 800px;
  font-weight: bold;
  min-width: 200px;
  padding-left: 10px;
}

.mapInput:active {
  border: none;
  outline: none;
}

.buttonPrimary {
  background-color: #187cae;
  margin: 10px;
  margin-top: 10px;
  max-height: 100px;
  border: none;
  padding: 8px 30px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.buttonPrimary:hover {
  background-color: #136188;
}

.appbarNavlink {
  text-decoration: none;
  font-weight: bolder;
  color: black;
  margin-right: 3vw;
  margin-top: 10px;
  cursor: pointer;
}

.logo {
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.responsiveFlex {
  margin: 0px;
  padding: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.greyInput {
  border: none !important;
  border-radius: 5px;
  padding: 8px 10px;
  background-color: #f4f4f4;
  width: 100%;
}

.greyInput1 {
  border: none !important;
  border-radius: 5px;
  padding: 8px 10px;
  background-color: #f4f4f4;
  width: 100%;
}

/* Mobile View */
@media only screen and (max-width: 768px) {
  .bm-burger-button {
    top: 3% !important;
  }
  .responsiveFlex {
    display: flex;
    flex-direction: column;
  }
  .logo {
    margin-left: 0px;
    margin-right: 0px;
    cursor: pointer;
    max-width: 50px;
  }

  .appbarNavlink {
    display: none;
  }
  * {
    font-family: sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .map {
    height: 50%;
    width: 100%;
    margin-top: 30px;
  }

  .mapTool {
    grid-template-columns: 1fr;
    grid-template-rows: 50% 25% 25%;
    grid-template-areas:
      "Map"
      "mapDrawerLeft"
      "mapDrawerRight";
  }

  .mapTools {
    flex-direction: row;
    display: block;
    margin: 0%;
  }

  .tool {
    margin: 0;
    text-align: center;
    /* width: 100vw;
    width: 100vh; */
    cursor: pointer;
    color: white;
    padding: 10px;
  }

  .mapDrawerLeft {
    grid-area: mapDrawerLeft;
    background-color: #292c31;
    align-items: center;
    display: flex;
    position: relative;
    place-items: start normal;
    justify-content: center;
  }
}

.login {
  /* padding: 25px; */
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.login_info {
  margin-top: 50px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  /* gap: 40px;
  min-width: 300px; */
}

.login_form {
  display: flex;
  text-align: left;
  flex-direction: column;
  text-align: left;
  align-items: center;
}

.login_form > label {
  color: #696b6e;
  font-weight: 600;
  font-size: 15px;
  padding-left: 20px;
  text-align: left;
  border-radius: 50px;
  min-width: 100px;
}

.labelText {
  color: #696b6e;
  font-weight: 600;
  font-size: 15px;
  padding-left: 33px;
  text-align: left;
  border-radius: 50px;
  min-width: 100px;
}

.login_form > input {
  margin: 10px;
  margin-bottom: 30px;
  margin-left: 15px;
  outline: none;
  border-radius: 10px;
  max-height: 40px;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  color: #373a47;
  width: 50%;
  max-width: 800px;
  font-weight: 600;
  min-width: 200px;
}

.loginInput {
  outline: none;
  border-radius: 28px;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  color: #373a47;
  max-width: 800px;
  font-weight: 600;
  min-width: 200px;
}

.buttonPrimary {
  background-color: #3e6ae1;
  margin: 10px;
  margin-top: 10px;
  max-height: 100px;
  border: none;
  padding: 8px 30px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.buttonPrimary:hover {
  background-color: #3457b1;
}

.login_divider {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.login_divider > span {
  font-weight: 600;
  color: #5c5e62;
}

.alternateLogin {
  color: #b6c4d9;
  font-weight: 500;
  width: 100%;
  font-size: 18px;
}

.alternateLogin > a,
a:hover {
  color: #b6c4d9;
  font-weight: 500;
  font-size: 18px;
  text-decoration: underline;
  background-color: transparent;
}

.navLink,
.navLink:hover {
  background-color: transparent;
}

* {
  box-sizing: border-box;
}

body {
  background-color: white;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 20px 0 0;
  height: 10px;
  width: 300px;
}

.progress-done {
  background: linear-gradient(to left, #5a52ff, #7bd9f6);
  box-shadow: 0 3px 3px -5px #5a52ff, 0 2px 5px #7bd9f6;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

@-webkit-keyframes example {
  0% {
    right: 100px;
  }
  100% {
    left: 100;
    right: 0px;
  }
}

@keyframes example {
  0% {
    right: 100px;
  }
  100% {
    left: 100;
    right: 0px;
  }
}

/* The element to apply the animation to */
.animate {
  position: relative;
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.custom-file-input {
  outline: none;
  border-radius: 10px;
  background-color: #1b1f20;
  color: #71768d;
  font-weight: 600;
  min-width: 200px;
  text-align: center;
  max-width: 600px;

  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 6px 12px;
  cursor: pointer;
}

.footer button {
  border-radius: 7px;
  width: 100px;
  height: 40px;
  background-color: #3457b1;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  margin: 5px;
  align-items: center;
}

.stepper{
background-color: #1b1f20!important;
color: blanchedalmond;

}

.MuiTypography-root .MuiStepLabel-label .MuiTypography-body2{
  color: white!important;
}

.signUpInput{
  border: 2px solid #EFF2F7 !important;
  border-radius: 5px;
  padding: 8px 10px;
  margin-top: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.toggleButton1{
  background-color: grey!important;
  margin-right: 5vw !important;
  border-radius: 5px;
  width: 100%;
  border: none;
  color: white!important;
  min-width: 100px;
  font-size: 100%!important;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}
.toggleButton1:active{
  background-color: #3457b1!important;
}

.toggleButton1:hover {
  background-color: #3457b1!important;
}
.findSchedule {
  display: block;
  max-width: 80% !important;
}

@media screen and (max-width: 955px) {
  .findSchedule {
    max-width: 80%;
  }
}

@media screen and (max-width: 949px) {
    .findSchedule {
      display: none;
    }
}
/* Used to style ProUser and its subbranch */

.date {
  color: white;
  border: 3px solid white;
  padding: 10px;
  border-radius: 28px;
}

.date:focus,
.date:active {
  border: 3px rgb(38, 114, 214);
}

::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(100%);
          filter: invert(100%);
}

.subheading {
  color: white;
  margin-top: 30px;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  .cardNavLink {
    font-size: small;
  }
}

.grid-container {
  display: grid;
  grid-template-areas: Procard Procontent;
  width: 100%;
  height: 100%;
  grid-template-columns: 10% 90%;
  background-color: #1b1f20;
}

.ProCard {
  grid-area: Procard;
  background-color: #1b1f20;
  width: 100%;
  height: 100%;
}

.ProContent {
  grid-area: Procontent;
  background-color: #1b1f20;
  width: 100%;
  height: 100%;
}

.sideCardFixed {
  width: 16%;
  height: 100vh;
  position: fixed;
  z-index: 1;
  background: #1b1f20;
  overflow-x: hidden;
  padding: 18px 20px;
  display: flex;
}

.flexCard {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.CardsJoined {
  display: flex;
  flex-direction: column;
}

.Card1 {
  background-color: #f6f4ff;
  background-image: linear-gradient(250deg, #b5a6f7 0%, #7f6dd1 74%);
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.Card2 {
  background-color: #11998e;
  background-image: linear-gradient(250deg, #38ef7d 0%, #11998e 74%);
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
}

.contentCard {
  margin-top: 30px;
  padding-right: 8%;
  padding-left: 20%;
  background-color: #1b1f20;
}

.cardSubcomponent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .sideCardFixed {
    width: 100vh;
    height: auto;
    font-size: 100%;
    overflow: scroll;
  }
  .flexCard {
    display: flex;
    flex-direction: row;
  }
  .CardsJoined {
    display: none;
    flex-direction: row;
  }
  .Card1 {
    font-size: 20%;
    padding: 0%;
  }
  .contentCard {
    margin: 0%;
    padding-left: 0%;
    width: 100vw;
    font-size: 50%;
  }
}

.login {
  /* padding: 25px; */
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.login_info {
  margin-top: 50px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  /* gap: 40px;
  min-width: 300px; */
}

.login_form {
  display: flex;
  text-align: left;
  flex-direction: column;
  text-align: left;
  align-items: center;
}

.login_form > label {
  color: #696b6e;
  font-weight: 600;
  font-size: 15px;
  padding-left: 20px;
  text-align: left;
  border-radius: 50px;
  min-width: 100px;
}

.labelText {
  color: #696b6e;
  font-weight: 600;
  font-size: 15px;
  padding-left: 33px;
  text-align: left;
  border-radius: 50px;
  min-width: 100px;
}

.login_form > input {
  margin: 10px;
  margin-bottom: 30px;
  margin-left: 15px;
  outline: none;
  border-radius: 10px;
  max-height: 40px;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  color: #373a47;
  width: 50%;
  max-width: 800px;
  font-weight: 600;
  min-width: 200px;
}

.loginInput {
  outline: none;
  border-radius: 28px;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  color: #373a47;
  max-width: 800px;
  font-weight: 600;
  min-width: 200px;
}

.buttonPrimary {
  background-color: #3e6ae1;
  margin: 10px;
  margin-top: 10px;
  max-height: 100px;
  border: none;
  padding: 8px 30px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.buttonPrimary:hover {
  background-color: #3457b1;
}

.login_divider {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.login_divider > span {
  font-weight: 600;
  color: #5c5e62;
}

.alternateLogin {
  color: #b6c4d9;
  font-weight: 500;
  width: 100%;
  font-size: 18px;
}

.alternateLogin > a,
a:hover {
  color: #b6c4d9;
  font-weight: 500;
  font-size: 18px;
  text-decoration: underline;
  background-color: transparent;
}

.navLink,
.navLink:hover {
  background-color: transparent;
}

* {
  box-sizing: border-box;
}

body {
  background-color: white;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 20px 0 0;
  height: 10px;
  width: 300px;
}

.progress-done {
  background: linear-gradient(to left, #5a52ff, #7bd9f6);
  box-shadow: 0 3px 3px -5px #5a52ff, 0 2px 5px #7bd9f6;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

@-webkit-keyframes example {
  0% {
    right: 100px;
  }
  100% {
    left: 100;
    right: 0px;
  }
}

@keyframes example {
  0% {
    right: 100px;
  }
  100% {
    left: 100;
    right: 0px;
  }
}

/* The element to apply the animation to */
.animate {
  position: relative;
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.custom-file-input {
  outline: none;
  border-radius: 10px;
  background-color: #1b1f20;
  color: #71768d;
  font-weight: 600;
  min-width: 200px;
  text-align: center;
  max-width: 600px;

  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 6px 12px;
  cursor: pointer;
}

.footer button {
  border-radius: 7px;
  width: 100px;
  height: 40px;
  background-color: #3457b1;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  margin: 5px;
  align-items: center;
}

.stepper{
background-color: #1b1f20!important;
color: blanchedalmond;

}

.MuiTypography-root .MuiStepLabel-label .MuiTypography-body2{
  color: white!important;
}

.signUpInput{
  border: 2px solid #EFF2F7 !important;
  border-radius: 5px;
  padding: 8px 10px;
  margin-top: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.toggleButton1{
  background-color: grey!important;
  margin-right: 5vw !important;
  border-radius: 5px;
  width: 100%;
  border: none;
  color: white!important;
  min-width: 100px;
  font-size: 100%!important;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}
.toggleButton1:active{
  background-color: #3457b1!important;
}

.toggleButton1:hover {
  background-color: #3457b1!important;
}
.findSchedule {
  display: block;
  max-width: 80% !important;
}

@media screen and (max-width: 955px) {
  .findSchedule {
    max-width: 80%;
  }
}

@media screen and (max-width: 949px) {
    .findSchedule {
      display: none;
    }
}
/* Used to style ProUser and its subbranch */

.date {
  color: white;
  border: 3px solid white;
  padding: 10px;
  border-radius: 28px;
}

.date:focus,
.date:active {
  border: 3px rgb(38, 114, 214);
}

::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(100%);
          filter: invert(100%);
}

.subheading {
  color: white;
  margin-top: 30px;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  .cardNavLink {
    font-size: small;
  }
}

.grid-container {
  display: grid;
  grid-template-areas: Procard Procontent;
  width: 100%;
  height: 100%;
  grid-template-columns: 10% 90%;
  background-color: #1b1f20;
}

.ProCard {
  grid-area: Procard;
  background-color: #1b1f20;
  width: 100%;
  height: 100%;
}

.ProContent {
  grid-area: Procontent;
  background-color: #1b1f20;
  width: 100%;
  height: 100%;
}

.sideCardFixed {
  width: 16%;
  height: 100vh;
  position: fixed;
  z-index: 1;
  background: #1b1f20;
  overflow-x: hidden;
  padding: 18px 20px;
  display: flex;
}

.flexCard {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.CardsJoined {
  display: flex;
  flex-direction: column;
}

.Card1 {
  background-color: #f6f4ff;
  background-image: linear-gradient(250deg, #b5a6f7 0%, #7f6dd1 74%);
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.Card2 {
  background-color: #11998e;
  background-image: linear-gradient(250deg, #38ef7d 0%, #11998e 74%);
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
}

.contentCard {
  margin-top: 30px;
  padding-right: 8%;
  padding-left: 20%;
  background-color: #1b1f20;
}

.cardSubcomponent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .sideCardFixed {
    width: 100vh;
    height: auto;
    font-size: 100%;
    overflow: scroll;
  }
  .flexCard {
    display: flex;
    flex-direction: row;
  }
  .CardsJoined {
    display: none;
    flex-direction: row;
  }
  .Card1 {
    font-size: 20%;
    padding: 0%;
  }
  .contentCard {
    margin: 0%;
    padding-left: 0%;
    width: 100vw;
    font-size: 50%;
  }
}

.h1Secondary {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #1b1c57;
  font-size: 48px;
  text-align: left;
  color: white;
  font-weight: 600;
  line-height: 60px;
  text-transform: capitalize;
  text-decoration: underline;
  -webkit-text-decoration-color: #187cae; /* Safari */
  text-decoration-color: #187cae;
  text-underline-offset: -2%;
}

.mobilewhoarewe {
  display: none;
}

.heroSection {
  display: flex;
  width: 100%;
}

.heroPic {
  display: block;
}

.whoarewePic {
  display: block;
}

.mobileBG {
  display: none;
}

/* Responsive for Full HD Screen(1080px) */
@media screen and (min-width: 1050px) {
    .heroPic {
        width: 100vw;
      display: block;
      min-height: 800px;
    }
    .heroSection {
        display: flex;
        width: 100%;
      }
}

/* Responsive for Full HD Screen(1080px) */
@media screen and (max-width: 1049px) {
  .heroPic {
    display: block;
    min-height: 800px;
  }
  .whoarewePic {
    max-width: 80%;
  }
  .heroSection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .mobileBG {
    display: none;
  }
}

/* Responsive for HD Screen */
@media screen and (max-width: 955px) {
    .heroPic {
      display: none;
    }
    .whoarewePic {
      max-width: 80%;
    }
    .heroSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .mobileBG {
      display: none;
      width: 20%;
    }
  }

/* Responsive for mid device */
@media screen and (max-width: 689px) {
    .heroPic {
      display: none;
    }
    .whoarewePic {
      max-width: 80%;
    }
    .heroSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .mobileBG {
        display: block;
      width: 65%;
    }
  }

/* Responsive for tab */
@media screen and (max-width: 949px) {
  .heroPic {
    display: none;
  }
  .whoarewePic {
    display: none;
  }
  .mobilewhoarewe {
    display: block;
  }
  .mobileBG {
    display: block;
    width: 55%;
  }
}

/* Responsive for Mobile */
@media screen and (max-width: 480px) {
    .mobileBG {
      display: block;
      width: 100%;
    }
    .heroPic {
      display: none;
    }
    .whoarewePic {
      display: none;
    }
    .mobilewhoarewe {
      display: flex;
    }
    .heroSection {
      display: flex;
      flex-direction: column;
    }
    .h1Secondary {
      font-size: 30px;
      line-height: 37.5px;
    }
  }

.cardImage {
    height: 20vh;
    width: 20vw;
    background-color: #555;
    /* margin-left: 100px;
    margin-bottom: 30px; */
  }
  
  .cardImageWrapper {
    display: flex;
  }
  
  .card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    height: 40vh;
    width: 34vw;
    margin-left: 30px;
    margin-bottom: 30px;
  }
  
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  
  /* Add some padding inside the card container */
  .container {
    padding: 2px 16px;
  }
  
  .label {
    color: #acaeb1;
    font-weight: 600;
    font-size: larger;
    padding-left: 30px;
    text-align: left;
    min-width: 100px;
    margin-top: 40px;
  }
  
  .sublabel {
    display: flex;
    margin-left: 30px;
    margin-top: 10px;
    padding: 10px 50px;
    background-color: #1e2324;
    width: 100%;
    color: white;
    font-size: large;
    margin-top: 10px;
    border-radius: 10px;
    left: 0;
    right: 0;
  }
  
  .hoverCard {
    background-color: #373f41;
    color: white;
  }
  
  @-webkit-keyframes animateDown {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
    40% {
      -webkit-transform: translateY(5px);
              transform: translateY(5px);
    }
    60% {
      -webkit-transform: translateY(3px);
              transform: translateY(3px);
    }
  }
  
  @keyframes animateDown {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
    40% {
      -webkit-transform: translateY(5px);
              transform: translateY(5px);
    }
    60% {
      -webkit-transform: translateY(3px);
              transform: translateY(3px);
    }
  }
  
  .fix-new {
    font-size: 10px !important; /*we can easly change credit card size*/
  }
  
  .container {
    flex-direction: unset;
    display: flex;
  }
  
  @media screen and (max-width: 840px) {
    .container {
      flex-direction: column-reverse;
    }
    .form-box {
      margin-right: 0rem;
      margin-top: 1rem;
    }
    
  }
  
  .card-new {
    /* background: url(./any_pattern.jpg) !important; */
    height: 20%;
    border: none !important;
    /* background: linear-gradient(to right top, #280537, #56034c, #890058, #bc005b, #eb1254) !important; */
    background: linear-gradient(200deg, #1382be, #0c548f) !important;
    margin-left: 0px;
    max-width: 100%;
  }
  
  .form-box {
    background-color: #1e2324;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    margin-right: 1rem;
    padding: 2%;
    padding-top: 0%;
  }
  
  .input-text {
    border: 1px solid #dddddd;
    background: #f8f8f8;
    padding: 0 1rem;
    line-height: 2.5rem;
    font-size: 1.1rem;
    border-radius: 0.1rem;
    width: 100%;
    margin: 1rem 0;
    color: #666666;
    max-width: 92%;
  }
  
  .semi {
    width: 43%;
  }
  
  .flex-wrapper {
    display: flex;
  }
  
  .flex-wrapper div {
    margin-right: 2rem;
  }
  
  .error {
    background-color: rgb(247, 144, 144);
  }
  
  .cancelButton{
    color:grey;
    margin-left: 10%;
  }
  
  .cancelButton:hover {
    color:red;
    cursor: pointer;
  
  }
  
  .cancelButton1{
    color:grey;
  }
  
  .cancelButton1:hover {
    color:red;
    cursor: pointer;
  
  }

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  margin: 0;
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
}

body {
  width: 100%;
  overflow-x: hidden;
}

.appbarNavlink {
  text-decoration: none;
  font-weight: bolder;
  color: black;
  margin-right: 3vw;
  margin-top: 10px;
  cursor: pointer;
}

.Container {
  height: 100vh !important;
}

.centered {
  display: grid;
  justify-content: center;
  align-items: center;
}

.button {
  height: 70% !important;
  width: 30% !important;
}

/* Burger Menu CSS [NavBar] */

.bm-item {
  display: inline-block;
  padding: 15px 0;
  border-bottom: 1px solid rgba(194, 192, 192, 0.2);
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 22px;
  right: 2rem;
  top: 25px;
}

.bm-burger-bars {
  background: #d2e4f5;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #1a1d1e;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 15px 0;
  border-bottom: 1px solid rgba(194, 192, 192, 0.2);
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.popup {
  margin-top: 1000px;
}

.right {
  float: right;
  background-color: #23282b;
}

/* Maptool Style */

.mapTool {
  display: grid;
  background-color: #171719;
  width: 100vw;
  height: 100vh;
  grid-template-columns: 15% 85%;
  grid-template-areas: "mapDrawerLeft Map";
}

.Map {
  grid-area: Map;
  padding: 15px;
  background-color: #171719;
  height: 100vh;
}

.map {
  height: 75%;
  width: 97%;
  margin-top: 20px;
  margin-left: 2%;
}

.mapHeader {
  color: white;
  padding-top: 20px;
  font: Roboto;
  font-weight: 400;
  font-size: 36;
  letter-spacing: 0.085em;
}

.saveToSheets {
  margin-top: 20px;
  padding: 10px;
  margin-right: 30px;
  border-radius: 30px;
  border: none;
  color: white;
  font-weight: bold;
  letter-spacing: 0.085em;
  background-color: rgba(44, 118, 230, 0.514);
  width: 20%;
}

.saveToSheets > a {
  color: white;
  text-decoration: none;
}

.mapZoneButton {
  display: inline;
  place-items: left;
  margin-top: 20px;
  padding: 10px;
  margin-right: 30px;
  border-radius: 30px;
  border: none;
  width: 10%;
}

.mapDrawerLeft {
  grid-area: mapDrawerLeft;
  align-items: left;
  background: linear-gradient(182.6deg, #146b99 -15.3%, #01132c 97.83%);
}

.mapDrawerRight {
  grid-area: mapDrawerLeft;
  background-color: #1b1f20;
  align-items: center;
  display: flex;
  position: relative;
  place-items: start normal;
  justify-content: center;
  float: left;
  padding-top: 10px;
  height: 100%;
  width: 50%;
  margin-left: 90px;
  box-shadow: 5px 0px 5px rgb(8, 8, 8);
}
.mapDrawerInfo {
  grid-area: mapDrawerRight;
  background-color: #1b1f20;
  align-items: center;
  display: block;
  position: relative;
  place-items: start normal;
  justify-content: center;
  padding-top: 10px;
  height: 100%;
  width: 100%;
  box-shadow: 5px 0px 5px rgb(8, 8, 8);
}

.mapTools {
  display: block;
  height: 8%;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.mapTools:hover {
  background-color: rgba(44, 118, 230, 0.514);
}

.mapTools:focus-within {
  background-color: rgba(44, 118, 230, 0.514);
}

.mapToolIcon {
  margin-top: 0;
  padding-top: 25%;
}
.tool {
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: white;
  padding: 10px;
}

.editTool {
  color: rgb(73, 70, 70);
  display: inline-block;
  font-size: 12px;
  padding-left: 130px;
  padding-bottom: 10px;
}

.editTool:hover {
  color: rgba(129, 120, 120, 0.993);
  font-size: 17px;
}

.video-responsive {
  justify-content: center;
  align-items: center;
  background-color: #1b1f20;
  color: white;
  width: 100vw;
  height: 100vh;
}

.video-responsive > video {
  width: 100vw;
  height: 80vh;
  padding-top: 5%;
  align-items: center;
  background-color: #1b1f20;
}

.video-responsive h1 {
  padding-top: 10vh;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.5px;
  padding: 0px;
  font-size: 5vh;
  color: white;
}

.search {
  font-size: 16px;
  padding: 20px;
  margin-bottom: 5%;
  height: 10%;
  border-radius: 30px;
  width: 80%;
  align-items: center;
  text-align: left;
  color: #233d53;
  background-color: white;
  min-width: 80%;
  margin-right: 10px;
  min-height: 20px;
  padding-left: 2%;
  font-family: Poppins;
}

.buttonResponsive {
  background-color: #187cae;
  border: none;
  border-radius: 28px;
  margin: 0px;
  padding: 5px 10px;
  font-weight: 500;
  white-space: nowrap;
  width: 100%;
  min-width: 175px;
  height: 100px;
  text-align: center;
  align-items: center;
  max-height: 50px;
  margin-bottom: 5%;
  margin-left: 10%;
  color: white;
  font-family: "Poppins";
  font-size: 20px;
  pointer: "cursor";
}

@media only screen and (max-width: 768px) {
  .buttonResponsive {
    font-size: 14px;
    width: 100%;
    min-width: 130px;
  }
  .search {
    min-width: 55%;
    margin-left: 10%;
  }
  * {
    font-family: "Poppins";
    /* color: #393c41; */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .map {
    height: 50%;
    width: 100%;
    margin-top: 30px;
  }

  .mapTool {
    grid-template-columns: 1fr;
    grid-template-rows: 50% 25% 25%;
    grid-template-areas:
      "Map"
      "mapDrawerLeft"
      "mapDrawerRight";
  }

  .mapTools {
    flex-direction: row;
    display: block;
    margin: 0%;
  }

  .tool {
    margin: 0;
    text-align: center;
    /* width: 100vw;
    width: 100vh; */
    cursor: pointer;
    color: white;
    padding: 10px;
  }

  .mapDrawerLeft {
    grid-area: mapDrawerLeft;
    background-color: #292c31;
    align-items: center;
    display: flex;
    position: relative;
    place-items: start normal;
    justify-content: center;
  }
}

.cardContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: 30vw;
  height: 60%;
  display: flex;
  flex-direction: column;
}

.card > form {
  margin: 10px;
  height: 100vh;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.timeslot {
  size: 12pc;
  display: inline;
  width: 100%;
  color: rgb(255, 255, 255);
  margin: 20px;
  justify-content: left;
  align-items: left;
  align-content: left;
  justify-items: left;
}

.pay {
  background-color: #3e6ae1;
  margin: 10px;
  margin-top: 30px;
  max-height: 100px;
  border: none;
  padding: 12px 40px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.mapInput {
  /* margin-top: 20px; */
  outline: none;
  border-radius: 10px;
  max-height: 50px;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  color: black;
  width: 30%;
  height: 20vh;
  max-width: 800px;
  font-weight: bold;
  min-width: 200px;
  padding-left: 10px;
}

.mapInput:active {
  border: none;
  outline: none;
}

.buttonPrimary {
  background-color: #3e6ae1;
  margin: 10px;
  margin-top: 10px;
  max-height: 100px;
  border: none;
  padding: 8px 30px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.buttonPrimary2 {
  background-color: #187cae;
  margin: 10px;
  margin-top: 10px;
  max-height: 100px;
  border: none;
  padding: 8px 30px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
  float: "right";
  display: "flex";
  justify-content: "flex-end";
}

.buttonPrimary3 {
  background-color: #ffffff;
  margin: 10px;
  margin-top: 10px;
  max-height: 100px;
  border: 4px solid #187cae;
  padding: 4px 15px;
  border-radius: 69px;
  color: #187cae;
  min-width: 100px;
  font-size: 100%;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.buttonPrimary:hover {
  background-color: #3457b1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

