/* Used to style ProUser and its subbranch */

.date {
  color: white;
  border: 3px solid white;
  padding: 10px;
  border-radius: 28px;
}

.date:focus,
.date:active {
  border: 3px rgb(38, 114, 214);
}

::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}

.subheading {
  color: white;
  margin-top: 30px;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  .cardNavLink {
    font-size: small;
  }
}

.grid-container {
  display: grid;
  grid-template-areas: Procard Procontent;
  width: 100%;
  height: 100%;
  grid-template-columns: 10% 90%;
  background-color: #1b1f20;
}

.ProCard {
  grid-area: Procard;
  background-color: #1b1f20;
  width: 100%;
  height: 100%;
}

.ProContent {
  grid-area: Procontent;
  background-color: #1b1f20;
  width: 100%;
  height: 100%;
}

.sideCardFixed {
  width: 16%;
  height: 100vh;
  position: fixed;
  z-index: 1;
  background: #1b1f20;
  overflow-x: hidden;
  padding: 18px 20px;
  display: flex;
}

.flexCard {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.CardsJoined {
  display: flex;
  flex-direction: column;
}

.Card1 {
  background-color: #f6f4ff;
  background-image: linear-gradient(250deg, #b5a6f7 0%, #7f6dd1 74%);
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.Card2 {
  background-color: #11998e;
  background-image: linear-gradient(250deg, #38ef7d 0%, #11998e 74%);
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
}

.contentCard {
  margin-top: 30px;
  padding-right: 8%;
  padding-left: 20%;
  background-color: #1b1f20;
}

.cardSubcomponent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-bottom: 20px;
  padding-left: 40px;
  padding-right: 40px;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .sideCardFixed {
    width: 100vh;
    height: auto;
    font-size: 100%;
    overflow: scroll;
  }
  .flexCard {
    display: flex;
    flex-direction: row;
  }
  .CardsJoined {
    display: none;
    flex-direction: row;
  }
  .Card1 {
    font-size: 20%;
    padding: 0%;
  }
  .contentCard {
    margin: 0%;
    padding-left: 0%;
    width: 100vw;
    font-size: 50%;
  }
}
