.findSchedule {
  display: block;
  max-width: 80% !important;
}

@media screen and (max-width: 955px) {
  .findSchedule {
    max-width: 80%;
  }
}

@media screen and (max-width: 949px) {
    .findSchedule {
      display: none;
    }
}