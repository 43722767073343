.cardImage {
    height: 20vh;
    width: 20vw;
    background-color: #555;
    /* margin-left: 100px;
    margin-bottom: 30px; */
  }
  
  .cardImageWrapper {
    display: flex;
  }
  
  .card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    height: 40vh;
    width: 34vw;
    margin-left: 30px;
    margin-bottom: 30px;
  }
  
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  
  /* Add some padding inside the card container */
  .container {
    padding: 2px 16px;
  }
  
  .label {
    color: #acaeb1;
    font-weight: 600;
    font-size: larger;
    padding-left: 30px;
    text-align: left;
    min-width: 100px;
    margin-top: 40px;
  }
  
  .sublabel {
    display: flex;
    margin-left: 30px;
    margin-top: 10px;
    padding: 10px 50px;
    background-color: #1e2324;
    width: 100%;
    color: white;
    font-size: large;
    margin-top: 10px;
    border-radius: 10px;
    left: 0;
    right: 0;
  }
  
  .hoverCard {
    background-color: #373f41;
    color: white;
  }
  
  @keyframes animateDown {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0px);
    }
    40% {
      transform: translateY(5px);
    }
    60% {
      transform: translateY(3px);
    }
  }
  
  .fix-new {
    font-size: 10px !important; /*we can easly change credit card size*/
  }
  
  .container {
    flex-direction: unset;
    display: flex;
  }
  
  @media screen and (max-width: 840px) {
    .container {
      flex-direction: column-reverse;
    }
    .form-box {
      margin-right: 0rem;
      margin-top: 1rem;
    }
    
  }
  
  .card-new {
    /* background: url(./any_pattern.jpg) !important; */
    height: 20%;
    border: none !important;
    /* background: linear-gradient(to right top, #280537, #56034c, #890058, #bc005b, #eb1254) !important; */
    background: linear-gradient(200deg, #1382be, #0c548f) !important;
    margin-left: 0px;
    max-width: 100%;
  }
  
  .form-box {
    background-color: #1e2324;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    margin-right: 1rem;
    padding: 2%;
    padding-top: 0%;
  }
  
  .input-text {
    border: 1px solid #dddddd;
    background: #f8f8f8;
    padding: 0 1rem;
    line-height: 2.5rem;
    font-size: 1.1rem;
    border-radius: 0.1rem;
    width: 100%;
    margin: 1rem 0;
    color: #666666;
    max-width: 92%;
  }
  
  .semi {
    width: 43%;
  }
  
  .flex-wrapper {
    display: flex;
  }
  
  .flex-wrapper div {
    margin-right: 2rem;
  }
  
  .error {
    background-color: rgb(247, 144, 144);
  }
  
  .cancelButton{
    color:grey;
    margin-left: 10%;
  }
  
  .cancelButton:hover {
    color:red;
    cursor: pointer;
  
  }
  
  .cancelButton1{
    color:grey;
  }
  
  .cancelButton1:hover {
    color:red;
    cursor: pointer;
  
  }

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  margin: 0;
}

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
}

body {
  width: 100%;
  overflow-x: hidden;
}

.appbarNavlink {
  text-decoration: none;
  font-weight: bolder;
  color: black;
  margin-right: 3vw;
  margin-top: 10px;
  cursor: pointer;
}

.Container {
  height: 100vh !important;
}

.centered {
  display: grid;
  justify-content: center;
  align-items: center;
}

.button {
  height: 70% !important;
  width: 30% !important;
}

/* Burger Menu CSS [NavBar] */

.bm-item {
  display: inline-block;
  padding: 15px 0;
  border-bottom: 1px solid rgba(194, 192, 192, 0.2);
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 26px;
  height: 22px;
  right: 2rem;
  top: 25px;
}

.bm-burger-bars {
  background: #d2e4f5;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #1a1d1e;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 15px 0;
  border-bottom: 1px solid rgba(194, 192, 192, 0.2);
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.popup {
  margin-top: 1000px;
}

.right {
  float: right;
  background-color: #23282b;
}

/* Maptool Style */

.mapTool {
  display: grid;
  background-color: #171719;
  width: 100vw;
  height: 100vh;
  grid-template-columns: 15% 85%;
  grid-template-areas: "mapDrawerLeft Map";
}

.Map {
  grid-area: Map;
  padding: 15px;
  background-color: #171719;
  height: 100vh;
}

.map {
  height: 75%;
  width: 97%;
  margin-top: 20px;
  margin-left: 2%;
}

.mapHeader {
  color: white;
  padding-top: 20px;
  font: Roboto;
  font-weight: 400;
  font-size: 36;
  letter-spacing: 0.085em;
}

.saveToSheets {
  margin-top: 20px;
  padding: 10px;
  margin-right: 30px;
  border-radius: 30px;
  border: none;
  color: white;
  font-weight: bold;
  letter-spacing: 0.085em;
  background-color: rgba(44, 118, 230, 0.514);
  width: 20%;
}

.saveToSheets > a {
  color: white;
  text-decoration: none;
}

.mapZoneButton {
  display: inline;
  place-items: left;
  margin-top: 20px;
  padding: 10px;
  margin-right: 30px;
  border-radius: 30px;
  border: none;
  width: 10%;
}

.mapDrawerLeft {
  grid-area: mapDrawerLeft;
  align-items: left;
  background: linear-gradient(182.6deg, #146b99 -15.3%, #01132c 97.83%);
}

.mapDrawerRight {
  grid-area: mapDrawerLeft;
  background-color: #1b1f20;
  align-items: center;
  display: flex;
  position: relative;
  place-items: start normal;
  justify-content: center;
  float: left;
  padding-top: 10px;
  height: 100%;
  width: 50%;
  margin-left: 90px;
  box-shadow: 5px 0px 5px rgb(8, 8, 8);
}
.mapDrawerInfo {
  grid-area: mapDrawerRight;
  background-color: #1b1f20;
  align-items: center;
  display: block;
  position: relative;
  place-items: start normal;
  justify-content: center;
  padding-top: 10px;
  height: 100%;
  width: 100%;
  box-shadow: 5px 0px 5px rgb(8, 8, 8);
}

.mapTools {
  display: block;
  height: 8%;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.mapTools:hover {
  background-color: rgba(44, 118, 230, 0.514);
}

.mapTools:focus-within {
  background-color: rgba(44, 118, 230, 0.514);
}

.mapToolIcon {
  margin-top: 0;
  padding-top: 25%;
}
.tool {
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: white;
  padding: 10px;
}

.editTool {
  color: rgb(73, 70, 70);
  display: inline-block;
  font-size: 12px;
  padding-left: 130px;
  padding-bottom: 10px;
}

.editTool:hover {
  color: rgba(129, 120, 120, 0.993);
  font-size: 17px;
}

.video-responsive {
  justify-content: center;
  align-items: center;
  background-color: #1b1f20;
  color: white;
  width: 100vw;
  height: 100vh;
}

.video-responsive > video {
  width: 100vw;
  height: 80vh;
  padding-top: 5%;
  align-items: center;
  background-color: #1b1f20;
}

.video-responsive h1 {
  padding-top: 10vh;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.5px;
  padding: 0px;
  font-size: 5vh;
  color: white;
}

.search {
  font-size: 16px;
  padding: 20px;
  margin-bottom: 5%;
  height: 10%;
  border-radius: 30px;
  width: 80%;
  align-items: center;
  text-align: left;
  color: #233d53;
  background-color: white;
  min-width: 80%;
  margin-right: 10px;
  min-height: 20px;
  padding-left: 2%;
  font-family: Poppins;
}

.buttonResponsive {
  background-color: #187cae;
  border: none;
  border-radius: 28px;
  margin: 0px;
  padding: 5px 10px;
  font-weight: 500;
  white-space: nowrap;
  width: 100%;
  min-width: 175px;
  height: 100px;
  text-align: center;
  align-items: center;
  max-height: 50px;
  margin-bottom: 5%;
  margin-left: 10%;
  color: white;
  font-family: "Poppins";
  font-size: 20px;
  pointer: "cursor";
}

@media only screen and (max-width: 768px) {
  .buttonResponsive {
    font-size: 14px;
    width: 100%;
    min-width: 130px;
  }
  .search {
    min-width: 55%;
    margin-left: 10%;
  }
  * {
    font-family: "Poppins";
    /* color: #393c41; */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .map {
    height: 50%;
    width: 100%;
    margin-top: 30px;
  }

  .mapTool {
    grid-template-columns: 1fr;
    grid-template-rows: 50% 25% 25%;
    grid-template-areas:
      "Map"
      "mapDrawerLeft"
      "mapDrawerRight";
  }

  .mapTools {
    flex-direction: row;
    display: block;
    margin: 0%;
  }

  .tool {
    margin: 0;
    text-align: center;
    /* width: 100vw;
    width: 100vh; */
    cursor: pointer;
    color: white;
    padding: 10px;
  }

  .mapDrawerLeft {
    grid-area: mapDrawerLeft;
    background-color: #292c31;
    align-items: center;
    display: flex;
    position: relative;
    place-items: start normal;
    justify-content: center;
  }
}

.cardContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: 30vw;
  height: 60%;
  display: flex;
  flex-direction: column;
}

.card > form {
  margin: 10px;
  height: 100vh;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.timeslot {
  size: 12pc;
  display: inline;
  width: 100%;
  color: rgb(255, 255, 255);
  margin: 20px;
  justify-content: left;
  align-items: left;
  align-content: left;
  justify-items: left;
}

.pay {
  background-color: #3e6ae1;
  margin: 10px;
  margin-top: 30px;
  max-height: 100px;
  border: none;
  padding: 12px 40px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.mapInput {
  /* margin-top: 20px; */
  outline: none;
  border-radius: 10px;
  max-height: 50px;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  color: black;
  width: 30%;
  height: 20vh;
  max-width: 800px;
  font-weight: bold;
  min-width: 200px;
  padding-left: 10px;
}

.mapInput:active {
  border: none;
  outline: none;
}

.buttonPrimary {
  background-color: #3e6ae1;
  margin: 10px;
  margin-top: 10px;
  max-height: 100px;
  border: none;
  padding: 8px 30px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.buttonPrimary2 {
  background-color: #187cae;
  margin: 10px;
  margin-top: 10px;
  max-height: 100px;
  border: none;
  padding: 8px 30px;
  border-radius: 50px;
  color: white;
  min-width: 100px;
  font-size: 100%;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
  float: "right";
  display: "flex";
  justify-content: "flex-end";
}

.buttonPrimary3 {
  background-color: #ffffff;
  margin: 10px;
  margin-top: 10px;
  max-height: 100px;
  border: 4px solid #187cae;
  padding: 4px 15px;
  border-radius: 69px;
  color: #187cae;
  min-width: 100px;
  font-size: 100%;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  margin-bottom: 30px;
  text-align: center;
  transition: all 0.4s;
  white-space: nowrap;
}

.buttonPrimary:hover {
  background-color: #3457b1;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
